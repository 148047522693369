import React from 'react';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  loadingContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    height: '100%',
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    '@media (max-width: 500px)': {
      padding: 10,
    },
  },
  logo: {
    height: 70,
    width: 70,
    marginBottom: 30,
    '@media (max-width: 500px)': {
      marginBottom: 10,
    },
  },
});

function Layout({ loading, title, children }) {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <img src="/assets/logo.png" alt="firstory" className={classes.logo} />
        </div>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          children
        )}
      </div>
    </>
  );
}

export default Layout;
