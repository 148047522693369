import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import LoginHome from './LoginHome';
import LoginForm from './LoginForm';
import { useFbLogin, useEmailLogin } from '../../gql/hooks/auth';
import AuthContext from './context';
import { UpdateUserProfile } from '../../gql/mutations';
import { useTracker } from '../../analytics/tracker';

const steps = {
  HOME: 0,
  EMAIL_PASSWORD: 1,
  NAME: 2,
};

function LoginOrSignup() {
  const intl = useIntl();
  const [fbLoading, setFbLoading] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [step, setStep] = React.useState(steps.HOME);
  const { fbLogin } = useFbLogin();
  const emailLogin = useEmailLogin();
  const [updateUserProfile] = useMutation(UpdateUserProfile);
  const { handleLogin, authGoNext } = React.useContext(AuthContext);
  const location = useLocation();
  const isSignup = useRouteMatch('/signup');
  const tracker = useTracker();

  React.useEffect(() => {
    tracker.clickSignupOrLogin({});
  }, [tracker]);

  const handleFbClick = React.useCallback(async () => {
    setFbLoading(true);
    const { error, result } = await fbLogin();
    setFbLoading(false);

    if (error) {
      alert(intl.formatMessage({ id: 'error.general' }));
      return;
    }

    handleLogin({ ...result, method: 'FB' });
    if (!result.hasInfo) {
      setStep(steps.NAME);
    }
  }, [intl, fbLogin, handleLogin]);

  const handleEmailClick = React.useCallback(() => {
    setStep(steps.EMAIL_PASSWORD);
    if (isSignup) {
      tracker.clickEmailSignup();
    } else {
      tracker.clickEmailLogin();
    }
  }, [tracker, isSignup]);

  const goBack = React.useCallback(() => {
    setStep(steps.HOME);
  }, []);

  const handleSubmit = React.useCallback(
    async ({ name, email, password }) => {
      setSubmitLoading(true);
      if (step === steps.NAME) {
        try {
          await updateUserProfile({
            variables: { name },
          });
          setSubmitLoading(false);
          authGoNext();
          return { success: true };
        } catch (error) {
          setSubmitLoading(false);
          return { error };
        }
      } else {
        const { error, result } = await emailLogin({
          isSignup,
          name,
          email,
          password,
        });
        setSubmitLoading(false);
        if (error) {
          return { error };
        }
        handleLogin({ ...result, method: 'EMAIL' });
        return { success: true };
      }
    },
    [step, isSignup, emailLogin, updateUserProfile, handleLogin, authGoNext],
  );

  React.useEffect(() => {
    setStep(0);
  }, [location.pathname]);

  return step === steps.HOME ? (
    <LoginHome
      isSignup={isSignup}
      fbLoading={fbLoading}
      onFbClick={handleFbClick}
      onEmailClick={handleEmailClick}
    />
  ) : (
    <LoginForm
      step={step}
      isSignup={isSignup}
      loading={submitLoading}
      goBack={goBack}
      onSubmit={handleSubmit}
    />
  );
}

export default LoginOrSignup;
