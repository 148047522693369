import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  AuthLoginWithTwoFa,
  AuthLoginWithEmail,
  AuthSignupWithEmail,
  AuthSignupWithFacebook,
  AuthPasswordSendResetEmail,
  AuthPasswordValidateResetToken,
  AuthPasswordReset,
  AuthPasswordUpdate,
  AuthEmailUpdate,
  AuthSwitchSession,
} from '../v2/auth';
import { useTracker } from '../../analytics/tracker';
import { loadFbSdk } from '../../utils';

export function useFbLogin() {
  const [signupWithFB] = useMutation(AuthSignupWithFacebook);
  const tracker = useTracker();

  const fbLogin = React.useCallback(async () => {
    try {
      tracker.clickFBSignup();
      const FB = await loadFbSdk();
      const response = await new Promise(resolve => FB.login(resolve));
      if (response.authResponse) {
        const { accessToken } = response.authResponse;
        const result = await signupWithFB({
          variables: { fbToken: accessToken },
        });
        const data = result.data.authSignupWithFacebook;
        return { result: data };
      }
    } catch (error) {
      tracker.error(error);
      return { error };
    }
  }, [tracker, signupWithFB]);

  React.useEffect(() => {
    loadFbSdk();
  }, []);

  return fbLogin;
}

export function useEmailLogin() {
  const [signup] = useMutation(AuthSignupWithEmail);
  const [login] = useMutation(AuthLoginWithEmail);
  const tracker = useTracker();

  const emailLogin = React.useCallback(
    async ({ isSignup, email, password }) => {
      if (isSignup) {
        try {
          const result = await signup({ variables: { email, password } });
          return { result: result.data.authSignupWithEmail };
        } catch (error) {
          tracker.error(error);
          return { error };
        }
      } else {
        try {
          const result = await login({ variables: { email, password } });
          return { result: result.data.authLoginWithEmail };
        } catch (error) {
          tracker.error(error);
          return { error };
        }
      }
    },
    [signup, login, tracker],
  );

  return emailLogin;
}

export function useLoginAndVerifyOtp() {
  const [fn] = useMutation(AuthLoginWithTwoFa);
  const loginAndVerifyOtp = React.useCallback(
    async ({ token, otp }) => {
      const result = await fn({
        variables: {
          otp,
          _token: token,
        },
      });
      return result.data.authLoginWithTwoFa;
    },
    [fn],
  );
  return loginAndVerifyOtp;
}

export function useUpdatePassword() {
  const [updatePasswordMutation] = useMutation(AuthPasswordUpdate);
  const updatePassword = React.useCallback(
    async values => {
      await updatePasswordMutation({ variables: values });
    },
    [updatePasswordMutation],
  );

  return updatePassword;
}

export function useUpdateUserEmailByPassword() {
  const [fn] = useMutation(AuthEmailUpdate);
  const updateEmail = React.useCallback(
    ({ email, password }) => {
      return fn({
        variables: {
          email,
          password,
        },
      });
    },
    [fn],
  );
  return updateEmail;
}

export function useAppleLogin({
  handleAppleIDSignInOnSuccess,
  handleAppleIDSignInOnFailure,
}) {
  React.useEffect(() => {
    document.addEventListener(
      'AppleIDSignInOnSuccess',
      handleAppleIDSignInOnSuccess,
    );
    document.addEventListener(
      'AppleIDSignInOnFailure',
      handleAppleIDSignInOnFailure,
    );
    return () => {
      document.addEventListener(
        'AppleIDSignInOnSuccess',
        handleAppleIDSignInOnSuccess,
      );
      document.addEventListener(
        'AppleIDSignInOnFailure',
        handleAppleIDSignInOnFailure,
      );
    };
  }, [handleAppleIDSignInOnSuccess, handleAppleIDSignInOnFailure]);
}

export function useForgetPassword() {
  const [fn] = useMutation(AuthPasswordSendResetEmail);
  const forgetPassword = React.useCallback(
    async email => {
      const result = await fn({
        variables: {
          email,
        },
      });
      return result.data.authPasswordSendResetEmail;
    },
    [fn],
  );
  return forgetPassword;
}

export function useCheckResetPasswordToken() {
  const [fn] = useMutation(AuthPasswordValidateResetToken);
  const checkResetPasswordToken = React.useCallback(
    async ({ email, token }) => {
      const result = await fn({
        variables: {
          email,
          token,
        },
      });
      return result.data.authPasswordValidateResetToken;
    },
    [fn],
  );
  return checkResetPasswordToken;
}

export function useResetPassword() {
  const [fn] = useMutation(AuthPasswordReset);
  const resetPassword = React.useCallback(
    async ({ email, token, password }) => {
      const result = await fn({
        variables: {
          email,
          token,
          password,
        },
      });
      return result.data.authPasswordReset;
    },
    [fn],
  );
  return resetPassword;
}

export function useSwitchSession() {
  const [fn] = useMutation(AuthSwitchSession);
  const switchSession = React.useCallback(
    async showId => {
      const result = await fn({
        variables: { showId },
      });
      return result.data.authSwitchSession;
    },
    [fn],
  );
  return switchSession;
}
