import React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NoSsr from '@material-ui/core/NoSsr';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LoginOrSignup from './LoginOrSignup';
import PasswordReset from './PasswordReset';
import RedirectWithStatus from '../../components/RedirectWithStatus';
import { lightTheme } from '../../theme';

const useStyles = makeStyles({
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
});

function Auth({ subdomain }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const background = location.state && location.state.background;
  const fullScreen = useMediaQuery('@media (max-width: 500px)');

  const back = React.useCallback(
    e => {
      e.stopPropagation();
      history.push(background);
    },
    [history, background],
  );

  if (global && global.location) {
    if (
      window.location.hostname.endsWith('firstory.io') &&
      subdomain !== 'auth'
    ) {
      const qs = new URLSearchParams(window.location.search);
      const token = qs.get('token');

      if (!token) {
        const redirectUrl = new URL(
          window.location.href.replace(subdomain, 'auth'),
        );
        redirectUrl.searchParams.set('redirectUrl', window.location.href);
        return (
          <RedirectWithStatus isExternal to={redirectUrl.href} status={301} />
        );
      }
    }
  }

  const content = (
    <NoSsr>
      <Switch>
        <Route path={['/login', '/signup']} exact>
          <LoginOrSignup />
        </Route>
        <Route path="/password-reset" component={PasswordReset} />
      </Switch>
    </NoSsr>
  );

  return (
    <ThemeProvider theme={lightTheme}>
      {background ? (
        <Dialog open fullScreen={fullScreen} fullWidth maxWidth="sm">
          {content}
          <IconButton onClick={back} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Dialog>
      ) : (
        content
      )}
    </ThemeProvider>
  );
}

export default Auth;
