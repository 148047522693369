import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Layout from './Layout';

const useStyles = makeStyles({
  loading: {
    color: 'white',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 30,
  },
  fbButton: {
    color: 'white',
    width: 300,
    height: 50,
    backgroundColor: '#3B5998',
    '&:hover': {
      backgroundColor: '#3B5998',
    },
    marginBottom: 20,
  },
  button: {
    width: 300,
    height: 50,
    marginBottom: 20,
  },
  gotoLoginHint: {
    marginTop: 10,
  },
  gotoSignupHint: {
    marginBottom: 20,
  },
});

function LoginHome({ isSignup, fbLoading, onFbClick, onEmailClick }) {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  const title = intl.formatMessage({
    id: isSignup ? 'auth.signup' : 'auth.login',
  });

  return (
    <Layout title={title}>
      <Typography color="textPrimary" variant="h5" className={classes.title}>
        {isSignup ? 'Join Firstory' : 'Welcome Back'}
      </Typography>
      <Button
        variant="contained"
        classes={{ root: classes.fbButton }}
        onClick={fbLoading ? null : onFbClick}
      >
        {fbLoading ? (
          <CircularProgress className={classes.loading} size={20} />
        ) : (
          <FormattedMessage
            id={isSignup ? 'auth.signupWithFB' : 'auth.loginWithFB'}
          />
        )}
      </Button>
      <Button
        color="primary"
        variant="contained"
        classes={{ root: classes.button }}
        onClick={onEmailClick}
      >
        <FormattedMessage
          id={isSignup ? 'auth.signupWithEmail' : 'auth.loginWithEmail'}
        />
      </Button>
      <Typography color="textPrimary" className={classes.gotoLoginHint}>
        <FormattedMessage
          id={isSignup ? 'auth.haveAccount' : 'auth.dontHaveAccount'}
        />{' '}
        <Link
          component={RouterLink}
          to={{
            pathname: isSignup ? '/login' : '/signup',
            state: location.state,
            search: location.search,
          }}
        >
          <FormattedMessage id={isSignup ? 'auth.login' : 'auth.signup'} />
        </Link>
      </Typography>
      {isSignup && (
        <Typography color="textPrimary" className={classes.gotoLoginHint}>
          <FormattedMessage id="auth.wantToCreatePodcast" />{' '}
          <Link
            component="a"
            href="https://studio.firstory.me"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FormattedMessage id="auth.gotoStudio" />
          </Link>
        </Typography>
      )}
    </Layout>
  );
}

export default LoginHome;
