import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useApolloClient } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Layout from './Layout';
import { ForgetPassword, CheckResetPasswordToken } from '../../gql/queries';
import { ResetPassword } from '../../gql/mutations';
import { useTracker } from '../../analytics/tracker';

const useStyles = makeStyles({
  loading: {
    color: 'white',
  },
  title: {},
  breadcrumbs: {
    marginBottom: '1em',
  },
  description: {
    marginBottom: '2em',
  },
  email: {
    display: 'block',
    backgroundColor: '#eee',
    margin: '10px 0',
    padding: '10px 20px',
    borderRadius: 5,
    fontWeight: 'bold',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: 300,
    marginBottom: '2em',
  },
  submitButton: {
    width: 300,
    height: 50,
  },
});

function PasswordReset() {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [values, setValues] = React.useState({
    email: '',
    token: '',
    password: '',
  });
  const [step, setStep] = React.useState(0);
  const tracker = useTracker();

  React.useEffect(() => {
    tracker.clickForgetPassword();
  }, [tracker]);

  const handleValueChange = name => e => {
    setValues({ ...values, [name]: e.target.value });
  };

  const handleSubmit = React.useCallback(
    async event => {
      event.preventDefault();
      if (step === 0) {
        if (values.email.trim() === '') {
          setErrorMessage('auth.emptyEmail');
          return;
        }
      } else if (step === 1) {
        if (values.token.trim() === '') {
          setErrorMessage('auth.emptyToken');
          return;
        }
      } else if (step === 2) {
        if (values.password.trim() === '') {
          setErrorMessage('auth.emptyPassword');
          return;
        }
      }
      try {
        setSubmitLoading(true);
        if (step === 0) {
          const result = await client.query({
            query: ForgetPassword,
            variables: values,
          });
          if (result.data.forgetPassword) {
            setErrorMessage(null);
            setStep(1);
            tracker.submitForgetPasswordEmail(values);
          } else {
            setErrorMessage('error.emailInvalid');
          }
        } else if (step === 1) {
          try {
            await client.query({
              query: CheckResetPasswordToken,
              variables: values,
            });
            setErrorMessage(null);
            setStep(2);
            tracker.submitForgetPasswordToken(values);
          } catch (error) {
            setErrorMessage('error.tokenInvalid');
          }
        } else if (step === 2) {
          try {
            await client.mutate({
              mutation: ResetPassword,
              variables: values,
            });
            tracker.submitNewPassword(values);
            alert('auth.passwordResetsuccess');
            history.push({ pathname: '/login', state: location.state });
          } catch (error) {}
        }
      } catch (error) {
      } finally {
        setSubmitLoading(false);
      }
    },
    [tracker, step, values, client, history, location],
  );

  const keys = ['email', 'token', 'password'];
  const descriptions = [
    intl.formatMessage({ id: 'auth.passwordReset.enterEmail' }),
    intl.formatMessage(
      { id: 'auth.passwordReset.emailSent' },
      { email: <span className={classes.email}>{values.email}</span> },
    ),
    intl.formatMessage({ id: 'auth.enterPassword' }),
  ];
  const inputLabels = [
    'Email',
    intl.formatMessage({ id: 'general.token' }),
    intl.formatMessage({ id: 'general.password' }),
  ];
  const inputTypes = ['email', 'text', 'password'];
  const title = intl.formatMessage({ id: 'auth.passwordReset.title' });
  return (
    <Layout title={title}>
      <Typography color="textPrimary" variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Breadcrumbs
        className={classes.breadcrumbs}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Typography color={step === 0 ? 'primary' : 'inherit'}>
          <FormattedMessage id="auth.breadcrumb.email" />
        </Typography>
        <Typography color={step === 1 ? 'primary' : 'inherit'}>
          <FormattedMessage id="auth.breadcrumb.verify" />
        </Typography>
        <Typography color={step === 2 ? 'primary' : 'inherit'}>
          <FormattedMessage id="auth.breadcrumb.password" />
        </Typography>
      </Breadcrumbs>
      <Typography color="textPrimary" className={classes.description}>
        {descriptions[step]}
      </Typography>
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField
          key={step}
          className={classes.input}
          label={inputLabels[step]}
          type={inputTypes[step]}
          variant="outlined"
          value={values[keys[step]]}
          error={!!errorMessage}
          helperText={errorMessage}
          onChange={handleValueChange(keys[step])}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitButton}
        >
          {submitLoading ? (
            <CircularProgress className={classes.loading} size={20} />
          ) : (
            <FormattedMessage id="general.submit" />
          )}
        </Button>
      </form>
    </Layout>
  );
}

export default PasswordReset;
