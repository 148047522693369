import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Layout from './Layout';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    paddingBottom: 20,
  },
  input: {
    width: 250,
    marginBottom: 20,
  },
  button: {
    height: 50,
    width: 250,
    marginTop: 20,
  },
  loading: {
    color: 'white',
  },
});

const steps = {
  HOME: 0,
  EMAIL_PASSWORD: 1,
  NAME: 2,
};

function LoginForm({ loading, step, hideName, isSignup, goBack, onSubmit }) {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    password: '',
  });
  const [errors, setErrors] = React.useState({
    name: null,
    email: null,
    password: null,
  });

  const title = intl.formatMessage({
    id:
      step === steps.EMAIL_PASSWORD
        ? isSignup
          ? 'auth.signupWithEmail'
          : 'auth.enterEmailPassword'
        : 'auth.enterName',
  });

  const showName = step === steps.NAME || (isSignup && !hideName);
  const showEmail = step === steps.EMAIL_PASSWORD;
  const showPassword = step === steps.EMAIL_PASSWORD;
  const showForgetPassword = step === steps.EMAIL_PASSWORD && !isSignup;

  const handleSubmit = React.useCallback(
    async event => {
      event.preventDefault();
      const name = values.name ? values.name.trim() : '';
      const email = values.email ? values.email.trim() : '';
      const password = values.password ? values.password.trim() : '';

      let valid = true;
      const err = {};
      if (showName && name.length === 0) {
        valid = false;
        err.name = 'error.emptyName';
      }
      if (showEmail && email.length === 0) {
        valid = false;
        err.email = 'error.emptyEmail';
      }
      if (showPassword && password.length === 0) {
        valid = false;
        err.password = 'error.emptyPassword';
      }
      if (!valid) {
        setErrors({ ...errors, ...err });
        return;
      }

      const { error } = await onSubmit(values);

      if (!error) {
        return;
      }

      if (error.graphQLErrors) {
        const { message } = error.graphQLErrors[0];
        if (message === 'name exists') {
          valid = false;
          err.name = 'error.nameExists';
        }
        if (message === 'email exists') {
          valid = false;
          err.email = 'error.emailExists';
        }
      }

      if (!valid) {
        setErrors({ ...errors, ...err });
        return;
      }

      if (isSignup) {
        alert(intl.formatMessage({ id: 'error.general' }));
      } else {
        alert(intl.formatMessage({ id: 'error.incorrectEamilPassword' }));
      }
    },
    [
      intl,
      errors,
      isSignup,
      showEmail,
      showPassword,
      showName,
      values,
      onSubmit,
    ],
  );

  const handleValueChange = name => e => {
    setValues({ ...values, [name]: e.target.value });
    setErrors({ ...errors, [name]: null });
  };

  return (
    <Layout>
      <form className={classes.container} onSubmit={handleSubmit}>
        <Typography color="textPrimary" variant="h5" className={classes.title}>
          {title}
        </Typography>
        {showName && (
          <TextField
            className={classes.input}
            label={intl.formatMessage({ id: 'general.name' })}
            variant="outlined"
            value={values.name}
            error={!!errors.name}
            helperText={
              errors.name ? intl.formatMessage({ id: errors.name }) : null
            }
            onChange={handleValueChange('name')}
          />
        )}
        {showEmail && (
          <TextField
            className={classes.input}
            label="Email"
            variant="outlined"
            type="email"
            value={values.email}
            error={!!errors.email}
            helperText={
              errors.email ? intl.formatMessage({ id: errors.email }) : null
            }
            onChange={handleValueChange('email')}
          />
        )}
        {showPassword && (
          <TextField
            className={classes.input}
            label={intl.formatMessage({ id: 'general.password' })}
            variant="outlined"
            type="password"
            value={values.password}
            error={!!errors.password}
            helperText={
              errors.password
                ? intl.formatMessage({ id: errors.password })
                : null
            }
            onChange={handleValueChange('password')}
          />
        )}
        {showForgetPassword && (
          <Link
            component={RouterLink}
            to={{
              pathname: '/password-reset',
              state: location.state,
            }}
          >
            <FormattedMessage id="auth.forgetPassword" />
          </Link>
        )}
        <Button
          type="submit"
          disabled={loading}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          {loading ? (
            <CircularProgress className={classes.loading} size={20} />
          ) : (
            <FormattedMessage id="general.submit" />
          )}
        </Button>
        <Button className={classes.button} onClick={goBack}>
          <FormattedMessage id="general.previous" />
        </Button>
      </form>
    </Layout>
  );
}

export default LoginForm;
